<template>
  <div class="m-pg-sub">
    <page-cover code="11" layer="rgba(0,0,0,.3)">
      <h2 class="m-page-title">我的预约</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/personal' }">{{$t('page.personal.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>我的预约</el-breadcrumb-item>
      </el-breadcrumb>
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <el-table
        class="el-table--custom"
        v-loading="table.loading"
        :data="table.data"
        row-key="AppointmentFormID"
        size="medium"
        stripe>
        <el-table-column
          prop="Name"
          label="预约单名称"
          width="300">
        </el-table-column>

        <el-table-column
          prop="name"
          label="实验数据"
          width="150"
          align="center">
          <template slot-scope="{ row, column, $index }">
            <el-button v-if="row.DUrl" type="primary" size="mini" circle icon="zm-icon zm-icon-antou" @click="onDownload(row.DUrl)"></el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="实验日志文件"
          width="150"
          align="center">
          <template slot-scope="{ row, column, $index }">
            <el-button v-if="row.DLog" type="primary" size="mini" circle icon="zm-icon zm-icon-antou" @click="onDownload(row.DLog)"></el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="状态"
          align="center">
          <template slot-scope="{ row, column, $index }">
            {{row.AuditStatus+''| dictMap(dict.list_lab_Status)}}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          header-align="center"
          align="left"
          prop="name"
          width="110"
          label="操作">
          <template slot-scope="{ row, column, $index }">
            <el-button v-if="row.AuditStatus !== 0" title="详情" size="mini" type="primary" icon="zm-icon zm-icon-chakan" style="margin-right: 6px;" @click="onDetail(row)"></el-button>
            <el-button v-if="row.AuditStatus === 0" title="编辑" size="mini" type="primary" icon="zm-icon zm-icon-jianyi" style="margin-right: 6px;" @click="onEdit(row)"></el-button>
            <el-popconfirm
              v-if="row.AuditStatus === 0"
              title="确定删除？"
              @confirm="onDel(row)">
              <el-button slot="reference" title="删除" size="mini" type="danger" icon="zm-icon zm-icon-delete"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-size.sync="page.size"
        :current-page.sync="page.index"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
    <detail-dialog
      :visible.sync="detailDialog.visible"
      :model="detailDialog.model">
    </detail-dialog>
    <edit-dialog
      :visible.sync="editDialog.visible"
      :model="editDialog.model">
    </edit-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DetailDialog from './detail-dialog'
import EditDialog from './edit-dialog'
import setting from '@/setting'
import commonApi from '@/api/common'
import labAppointmentFormPApi from '@/api/labAppointmentFormP'
export default {
  components: {
    DetailDialog,
    EditDialog
  },
  data() {
    return {
      loading: false,
      dict: {
        list_lab_Status: [], // 预约状态
      },
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 10,
        total: 0
      },
      detailDialog: {
        visible: false,
        model: {}
      },
      editDialog: {
        visible: false,
        model: {}
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  inject: ['validateLogin'],
  methods: {
    async getDict() {
      const dicts = await commonApi.getVueDictionary([
        "list_lab_Status"
      ])
      if (dicts && dicts.length) {
        this.dict.list_lab_Status = dicts.find(item => item.dicNo === 'list_lab_Status').data
      }
    },
    getData() {
      this.table.loading = true
      let wheres = []
      wheres.push({name: 'CreateID', value: this.user.user_Id, displayType: 'text'})
      wheres.push({name: 'IsWeb', value:1, displayType: 'text'})
      labAppointmentFormPApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: this.page.index,
        rows: this.page.size
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.table.loading = false
      })
    },
    onDetail(row) {
     this.detailDialog.visible = true
     this.detailDialog.model = JSON.parse(JSON.stringify(row))
    },
    onEdit(row) {
     this.editDialog.visible = true
     this.editDialog.model = JSON.parse(JSON.stringify(row))
    },
    onDel(row) {
      this.table.loading = true
      let wheres = []
      labAppointmentFormPApi.del([row.AppointmentFormID]).then(res => {
        this.table.loading = false
        if(!res.status) {
          this.$message.error({
            message: res.message
          })
          return
        }
        this.$message.success({
          message: '已删除'
        })
        this.getData()
      }).catch(err => {
        this.table.loading = false
      })
    },
    onPageChange(val) {
      this.getData()
    },
    onDownload(url) {
      window.open(setting.baseUrl.default + '/' + url, '_blank')
    }
  },
  created() {
    this.validateLogin()
  },
  mounted() {
    if (!this.user || !this.user.user_Id) {
      return;
    }
    this.getDict()
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .filter {
      padding: 20px 0;
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      /deep/ .el-input {
        width: 193px;
      }
    }
    .step {
      margin-top: 30px;
    }
    .tb-data {
      // margin-top: 30px;
    }
  }
</style>
