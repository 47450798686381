<template>
  <el-dialog
    :visible.sync="self_visible"
    title=""
    width="80%"
    destroy-on-close>

    <div class="m-group">
      <div class="m-group_head">
        <div class="m-group_head-title">
          <i class="m-group_head-icon zm-icon zm-icon-jianyi"></i>
          <span class="m-group_head-title_text">仪器预约单</span>
        </div>
      </div>
      <div class="m-group_body">
        <el-form ref="model" class="model" label-width="110px" :model="model" label-suffix=":">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="预约设备名称">
                {{model.Lab_apparatusName}}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="预约单号">
                {{model.AppointmentFormNO}}
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="预约单名称">
                {{model.Name}}
              </el-form-item>
            </el-col>
            <hr /><br />
            <el-col :span="12">
              <el-form-item label="申请类型">
                {{model.AppointmentType+'' | dictMap(dict.AppointmentType)}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请人" prop="A_Applicant">
                {{model.A_Applicant}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请主体" prop="A_ApplicationUnit">
                {{model.A_ApplicationUnit}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主体负责人" prop="A_Principal">
                {{model.A_Principal}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="Tel">
                {{model.Tel}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据发送邮件" prop="Send_email">
                {{model.Send_email}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="科学问题" prop="remarks">
                {{model.remarks}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="分析目的" prop="remarks2FXMD">
                {{model.remarks2FXMD}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预期成果" prop="remarks2YQCG">
                {{model.remarks2YQCG}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同">
                {{model.DContractFile ? model.DContractFile.split('/')[model.DContractFile.split('/').length -1] : ''}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="AuditStatus">
                {{model.AuditStatus+''| dictMap(dict.list_lab_Status)}}
              </el-form-item>
            </el-col>
            <el-col v-if="model.Auditreason" :span="12">
              <el-form-item label="说明" prop="Auditreason">
                {{model.Auditreason}}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="m-group">
      <div class="m-group_head">
        <div class="m-group_head-title">
          <i class="m-group_head-icon zm-icon zm-icon-list"></i>
          <span class="m-group_head-title_text">样品清单</span>
        </div>
      </div>
      <div class="m-group_body" style="padding-top: 0;">
        <el-table
          class="el-table--custom"
          :data="detail"
          row-key="ex_id"
          size="medium"
          stripe>

          <el-table-column
            fixed="left"
            label="序号"
            width="55"
            >
            <template slot-scope="{row, column, $index}">
              <template>{{$index}}</template>
            </template>
          </el-table-column>

          <el-table-column
            prop="IDCode"
            label="样品编号"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              <template>{{row.IDCode}}</template>
            </template>
          </el-table-column>

          <el-table-column
            prop="Name"
            label="岩性"
            width="150"
            >
            <template slot-scope="{row, column, $index}">
              <template>{{row.Name}}</template>
            </template>
          </el-table-column>

          <el-table-column
            prop="SampleType"
            label="样品类别"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.SampleType | dictMap(dict.list_lab_SampleType)}}
            </template>
          </el-table-column>

          <el-table-column
            prop="SampleName"
            label="样品名称"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.SampleName}}
            </template>
          </el-table-column>

          <el-table-column
            prop="SampleAdd"
            label="采样地理位置"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.SampleAdd}}
            </template>
          </el-table-column>

          <el-table-column
            prop="longitude"
            label="采样经度"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.longitude}}
            </template>
          </el-table-column>

          <el-table-column
            prop="latitude"
            label="采样纬度"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.latitude}}
            </template>
          </el-table-column>

          <el-table-column
            prop="TestItems"
            label="分析测试项目"
            width="240">
            <template slot-scope="{row}">
              {{row.TestItems | mapTestItem(testItems)}}
            </template>
          </el-table-column>

          <el-table-column
            prop="BeginDate"
            label="起始时间"
            width="200"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.BeginDate}}
            </template>
          </el-table-column>

          <el-table-column
            prop="EndDate"
            label="结束时间"
            width="200"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.EndDate}}
            </template>
          </el-table-column>

          <el-table-column
            prop="UnitPrice"
            label="单价(元)"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.UnitPrice}}
            </template>
          </el-table-column>

          <el-table-column
            prop="Metering"
            label="计量单位"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.Metering}}
            </template>
          </el-table-column>

          <el-table-column
            prop="lcount"
            label="数量"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.lcount}}
            </template>
          </el-table-column>

          <el-table-column
            prop="money"
            label="金额(元)"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.money}}
            </template>
          </el-table-column>

          <el-table-column
            prop="remarks"
            label="备注"
            width="150"
            show-overflow-tooltip>
            <template slot-scope="{row}">
              {{row.remarks}}
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import commonApi from '@/api/common'
import labApparatusApi from '@/api/labApparatus'
import labApparatusItemApi from '@/api/labApparatusItem'
import labAppointmentFormPApi from '@/api/labAppointmentFormP'
export default {
  name: 'reserve-detail',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      self_visible: this.visible,
      labApparatus: {}, // 实验仪器
      dict: {
        list_lab_SampleType: [], // 样品类型
        list_lab_Status: [], // 预约状态
        AppointmentType: [] // 预约类型
      },
      detail: [],
      testItems: [] // 测试项目
    }
  },
  watch: {
    visible(val) {
      this.self_visible = val
    },
    self_visible(val) {
      this.$emit('update:visible', val)
    },
    'model.AppointmentFormID'(val) {
      this.getmodelDetail()
    }
  },
  filters: {
    mapTestItem(val, items) {
      let model = items.find(item => item.Lab_apparatus_item_ID == val)
      return model ? model.Name : ''
    }
  },
  methods: {
    async getDict() {
      const dicts = await commonApi.getVueDictionary([
        "list_lab_SampleType",
        "list_lab_Status",
        'AppointmentType' // 预约类型
      ])
      if (dicts && dicts.length) {
        this.dict.list_lab_SampleType = dicts.find(item => item.dicNo === 'list_lab_SampleType').data
        this.dict.list_lab_Status = dicts.find(item => item.dicNo === 'list_lab_Status').data
        this.dict.AppointmentType = dicts.find(item => item.dicNo === 'AppointmentType').data
      }
    },
    getLabApparatus() {
      this.loading = true
      let wheres = []
      wheres.push({name: 'Lab_apparatus_ID', value: this.$route.query.labApparatusId, displayType: 'text'})
      labApparatusApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: 1,
        rows: 1
      }).then(res => {
        this.loading = false
        this.labApparatus = res.rows[0]
      }).catch(err => {
        this.loading = false
      })
    },
    getLabApparatusTestItem() {
      this.loading = true
      let wheres = []
      wheres.push({name: 'Lab_apparatus_ID', value: this.$route.query.labApparatusId, displayType: 'text'})
      labApparatusItemApi.getPageData({
        wheres: JSON.stringify(wheres),
        page: 1,
        rows: 1000
      }).then(res => {
        this.loading = false
        this.testItems = res.rows
      }).catch(err => {
        this.loading = false
      })
    },
    getmodelDetail() {
      this.loading = true
      labAppointmentFormPApi.getDetailPage({
        value: this.model.AppointmentFormID,
        page: 1,
        rows: 1000
      }).then(res => {
        this.loading = false
        this.detail = res.rows
      }).catch(err => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getDict()
    this.getLabApparatus()
    this.getLabApparatusTestItem()
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 20px;
}
</style>
